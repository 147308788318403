<template>
  <v-container v-if="apiLoaded">
    <div class="project-card">
      <h1>
        <span>{{ projectCommonInfo.name }}</span>
      </h1>

      <div class="badge">
        {{
          projectCommonInfo.status
            ? projectStatusDict[projectCommonInfo.status]
            : '-'
        }}
      </div>

      <div class="tabs-wrap">
        <v-tabs>
          <v-tab>Информация</v-tab>
          <v-tab>Финансы</v-tab>
          <v-tab>Участники и вклады</v-tab>

          <v-tab-item>
            <div class="buttons">
              <v-btn @click="$router.push('/projectEdit/' + projectId)"
                >Редактировать</v-btn
              >
            </div>

            <div class="grid30">
              <div>
                <h2>Общая информация</h2>

                <h4>№</h4>
                <p>{{ projectCommonInfo.number }}</p>

                <h4>дата начала и окончания</h4>
                <p>
                  {{ projectCommonInfo.startDate | formatDate }} –
                  {{ projectCommonInfo.endDate | formatDate }}
                </p>

                <h4>Руководитель</h4>
                <p v-if="projectCommonInfo.offerExpert">
                  <router-link
                    :to="
                      '/employee/' + projectCommonInfo.offerExpert.id + '/info'
                    "
                    >{{ offerExpertName }}</router-link
                  >
                </p>

                <h4>направления</h4>
                <div>
                  <span
                    v-for="(market, marketIndex) in projectCommonInfo.markets"
                    :key="marketIndex + 'market_'"
                  >
                    {{ supMarketDict[market] }}
                  </span>
                </div>

                <v-checkbox
                  label="Без договора"
                  readonly
                  v-model="projectCommonInfo.noContract"
                ></v-checkbox>
              </div>

              <div>
                <h2>Описание</h2>
                {{ projectCommonInfo.description }}
              </div>
            </div>

            <div class="grid50">
              <div>
                <h2>
                  Договоры с заказчиками
                  <v-btn outlined>Редактировать</v-btn>
                </h2>
                <p v-for="(item, index) in contracts" v-bind:key="item + index">
                  {{ item }}
                </p>
              </div>

              <div>
                <h2>
                  Договоры с подрядчиками
                  <v-btn outlined>Редактировать</v-btn>
                </h2>
              </div>
            </div>
          </v-tab-item>

          <v-tab-item>
            <div class="buttons">
              <v-btn>Редактировать</v-btn>
            </div>
            <!--  -->
            <v-expansion-panels v-model="panel" :disabled="disabled" multiple>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <h2>Финансы проекта</h2>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div class="finance-graph">
                    <v-btn-toggle>
                      <v-btn active> Факт </v-btn>
                      <v-btn> План </v-btn>
                    </v-btn-toggle>
                    <v-select dense outlined></v-select>

                    <img
                      src="~@/assets/graph.png"
                      alt=""
                      style="width: 100%; display: block; margin: 30px 0 40px 0"
                    />

                    <div class="legend">
                      <div
                        style="
                          background: #6fcf97;
                          color: #fff;
                          border: 1px solid #6fcf97;
                        "
                      >
                        Доходы
                      </div>
                      <div
                        style="
                          background: #eb5757;
                          color: #fff;
                          border: 1px solid #eb5757;
                        "
                      >
                        Расходы
                      </div>
                      <div
                        style="
                          background: #fff;
                          color: #000;
                          border: 1px solid #f2c94c;
                        "
                      >
                        Перераспределение
                      </div>
                      <div
                        style="
                          background: #3577be;
                          color: #fff;
                          border: 1px solid #3577be;
                        "
                      >
                        Прибыль
                      </div>
                    </div>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  <h2>Паспорт</h2>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div>
                    <v-btn outlined rounded class="download"></v-btn>
                    <v-btn>Все проводки</v-btn>
                  </div>
                  <table class="passport">
                    <thead>
                      <tr>
                        <th colspan="2">статья</th>
                        <th>план</th>
                        <th>факт</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="bold">
                        <td>
                          <v-checkbox></v-checkbox>
                        </td>
                        <td>
                          <strong> Сумма контракта </strong>
                        </td>
                        <td>
                          <strong>
                            {{
                              projectFinance.contractSum.plan.toLocaleString(
                                'ru'
                              ) + '&nbsp;₽'
                            }}
                          </strong>
                        </td>
                        <td>
                          <strong>
                            {{
                              projectFinance.contractSum.fact.toLocaleString(
                                'ru'
                              ) + '&nbsp;₽'
                            }}
                          </strong>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>
                          <v-checkbox></v-checkbox>
                        </td>
                        <td>
                          1.1. НДС согласно контракту за вычетом НДС
                          уплачиваемого соисполнителем
                        </td>
                        <td>
                          {{
                            projectFinance.nds.plan.toLocaleString('ru') +
                            '&nbsp;₽'
                          }}
                        </td>
                        <td>
                          {{
                            projectFinance.nds.fact.toLocaleString('ru') +
                            '&nbsp;₽'
                          }}
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>
                          <v-checkbox></v-checkbox>
                        </td>
                        <td>1.2. Безналичная оплата соисполнителей</td>
                        <td>
                          {{
                            projectFinance.cashlessPayment.plan.toLocaleString(
                              'ru'
                            ) + '&nbsp;₽'
                          }}
                        </td>
                        <td>
                          {{
                            projectFinance.cashlessPayment.fact.toLocaleString(
                              'ru'
                            ) + '&nbsp;₽'
                          }}
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>
                          <v-checkbox></v-checkbox>
                        </td>
                        <td>
                          1.3. Безвозвратное обеспечение (премии страховым
                          компаниям, комиссии банков за банковсике гарантии и
                          т.п.)
                        </td>
                        <td>
                          {{
                            projectFinance.irretrievablуGuarantee.plan.toLocaleString(
                              'ru'
                            ) + '&nbsp;₽'
                          }}
                        </td>
                        <td>
                          {{
                            projectFinance.irretrievablуGuarantee.fact.toLocaleString(
                              'ru'
                            ) + '&nbsp;₽'
                          }}
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>
                          <v-checkbox></v-checkbox>
                        </td>
                        <td>1.4. Безналичный резерв</td>
                        <td>
                          {{
                            projectFinance.cashlessReserve.plan.toLocaleString(
                              'ru'
                            ) + '&nbsp;₽'
                          }}
                        </td>
                        <td>
                          {{
                            projectFinance.cashlessReserve.fact.toLocaleString(
                              'ru'
                            ) + '&nbsp;₽'
                          }}
                        </td>
                        <td></td>
                      </tr>
                      <tr class="bold">
                        <td></td>
                        <td>
                          <strong> Vi Сумма Контракта без НДС </strong>
                          , за вычетом безвозвратного обеспечения и сумм,
                          выплаченных организациям-соисполнителям
                        </td>
                        <td>
                          <strong>
                            {{
                              projectFinance.viContractSum.plan.toLocaleString(
                                'ru'
                              ) + '&nbsp;₽'
                            }}
                          </strong>
                        </td>
                        <td>
                          <strong>
                            {{
                              projectFinance.viContractSum.fact.toLocaleString(
                                'ru'
                              ) + '&nbsp;₽'
                            }}
                          </strong>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td></td>
                        <td>Ставка налога</td>
                        <td>
                          {{ projectFinance.taxRate.toLocaleString('ru') }} %
                        </td>
                        <td></td>
                        <td><a href="#">изменить ставку</a></td>
                      </tr>
                      <tr>
                        <td>
                          <v-checkbox></v-checkbox>
                        </td>
                        <td>2.1. Сумма налога</td>
                        <td>
                          {{
                            projectFinance.taxSum.plan.toLocaleString('ru') +
                            '&nbsp;₽'
                          }}
                        </td>
                        <td>
                          {{
                            projectFinance.taxSum.fact.toLocaleString('ru') +
                            '&nbsp;₽'
                          }}
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td></td>
                        <td>Ставка комиссии банка за снятие наличности</td>
                        <td>
                          {{
                            projectFinance.cashingBankComission.toLocaleString(
                              'ru'
                            )
                          }}
                          %
                        </td>
                        <td></td>
                        <td><a href="#">изменить ставку</a></td>
                      </tr>
                      <tr>
                        <td>
                          <v-checkbox></v-checkbox>
                        </td>
                        <td>2.2. Сумма комиссии</td>
                        <td>
                          {{
                            projectFinance.cashingComissionSum.plan.toLocaleString(
                              'ru'
                            ) + '&nbsp;₽'
                          }}
                        </td>
                        <td>
                          {{
                            projectFinance.cashingComissionSum.fact.toLocaleString(
                              'ru'
                            ) + '&nbsp;₽'
                          }}
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td></td>
                        <td>Ставка средних накладных налогов</td>
                        <td>
                          {{ projectFinance.overheadRate.toLocaleString('ru') }}
                          %
                        </td>
                        <td></td>
                        <td><a href="#">изменить ставку</a></td>
                      </tr>
                      <tr>
                        <td>
                          <v-checkbox></v-checkbox>
                        </td>
                        <td>2.3. Сумма накладных расходов на проект</td>
                        <td>
                          {{
                            projectFinance.overheadAmountSum.plan.toLocaleString(
                              'ru'
                            ) + '&nbsp;₽'
                          }}
                        </td>
                        <td>
                          {{
                            projectFinance.overheadAmountSum.fact.toLocaleString(
                              'ru'
                            ) + '&nbsp;₽'
                          }}
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td></td>
                        <td>Ставка расходов на ЗП сотрудников</td>
                        <td>
                          {{
                            projectFinance.payrollCostRate.toLocaleString('ru')
                          }}
                          %
                        </td>
                        <td></td>
                        <td><a href="#">изменить ставку</a></td>
                      </tr>
                      <tr>
                        <td>
                          <v-checkbox></v-checkbox>
                        </td>
                        <td>2.4. ЗП сотрудников на проект</td>
                        <td>
                          {{
                            projectFinance.payrollAmountSum.plan.toLocaleString(
                              'ru'
                            ) + '&nbsp;₽'
                          }}
                        </td>
                        <td>
                          {{
                            projectFinance.payrollAmountSum.fact.toLocaleString(
                              'ru'
                            ) + '&nbsp;₽'
                          }}
                        </td>
                        <td></td>
                      </tr>
                      <tr class="bold">
                        <td></td>
                        <td>
                          <strong> Hi Сумма Контракта без НДС </strong>
                          , за вычетом безвозвратного обеспечения и сумм,
                          выплаченных организациям-соисполнителям, за вычетом
                          постоянных издержек
                        </td>
                        <td>
                          <strong>
                            {{
                              projectFinance.hiContractSum.plan.toLocaleString(
                                'ru'
                              ) + '&nbsp;₽'
                            }}
                          </strong>
                        </td>
                        <td>
                          <strong>
                            {{
                              projectFinance.hiContractSum.fact.toLocaleString(
                                'ru'
                              ) + '&nbsp;₽'
                            }}
                          </strong>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>
                          <v-checkbox></v-checkbox>
                        </td>
                        <td>3.1. Схема бонусов</td>
                        <td>
                          {{
                            projectFinance.bonusScheme.plan.toLocaleString(
                              'ru'
                            ) + '&nbsp;₽'
                          }}
                        </td>
                        <td>
                          {{
                            projectFinance.bonusScheme.fact.toLocaleString(
                              'ru'
                            ) + '&nbsp;₽'
                          }}
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>
                          <v-checkbox></v-checkbox>
                        </td>
                        <td>3.2. Оплата внешних экспертов</td>
                        <td>
                          {{
                            projectFinance.externalExpertsPayout.plan.toLocaleString(
                              'ru'
                            ) + '&nbsp;₽'
                          }}
                        </td>
                        <td>
                          {{
                            projectFinance.externalExpertsPayout.fact.toLocaleString(
                              'ru'
                            ) + '&nbsp;₽'
                          }}
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>
                          <v-checkbox></v-checkbox>
                        </td>
                        <td>3.3. Другие проектные расходы</td>
                        <td>
                          {{
                            projectFinance.otherProjectExpences.plan.toLocaleString(
                              'ru'
                            ) + '&nbsp;₽'
                          }}
                        </td>
                        <td>
                          {{
                            projectFinance.otherProjectExpences.fact.toLocaleString(
                              'ru'
                            ) + '&nbsp;₽'
                          }}
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>
                          <v-checkbox></v-checkbox>
                        </td>
                        <td>3.4. Резерв</td>
                        <td>
                          {{
                            projectFinance.reserve.plan.toLocaleString('ru') +
                            '&nbsp;₽'
                          }}
                        </td>
                        <td>
                          {{
                            projectFinance.reserve.fact.toLocaleString('ru') +
                            '&nbsp;₽'
                          }}
                        </td>
                        <td></td>
                      </tr>
                      <tr class="bold">
                        <td></td>
                        <td>
                          <strong>Pri - Прибыль проекта</strong>
                          (Сумма контрактов без НДС, безвозвр.обеспеч.,сумм
                          организаций-соисполнителей, за вычетом постоянных
                          издержек, за вычетом переменных издержек)
                        </td>
                        <td>
                          <strong>
                            {{
                              projectFinance.priProjectProfit.plan.toLocaleString(
                                'ru'
                              ) + '&nbsp;₽'
                            }}
                          </strong>
                        </td>
                        <td>
                          <strong>
                            {{
                              projectFinance.priProjectProfit.fact.toLocaleString(
                                'ru'
                              ) + '&nbsp;₽'
                            }}
                          </strong>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td></td>
                        <td>
                          Profit Доля проекта в прибыли (Отношение прибыли
                          проекта к прибыли команды)
                        </td>
                        <td>
                          {{
                            projectFinance.profitRate.plan.toLocaleString('ru')
                          }}
                          %
                        </td>
                        <td>
                          {{
                            projectFinance.profitRate.fact.toLocaleString('ru')
                          }}
                          %
                        </td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <!-- <h2>Проводки</h2> -->
                  <h2>
                    <span> Проводки </span>
                  </h2>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div>
                    <v-btn outlined rounded class="download"></v-btn>
                    <a :href="`/newwiring/${projectId}`">
                      <v-btn>Создать проводку</v-btn>
                    </a>
                  </div>
                  <section>
                    <!-- <h2>
                      <span> Проводки </span>
                      <v-btn outlined rounded class="download"></v-btn>
                      <a :href="`/newwiring/${projectId}`">
                        <v-btn>Создать проводку</v-btn>
                      </a>
                    </h2> -->
                    <table class="table_font">
                      <thead>
                        <tr>
                          <th>
                            <svg
                              width="10"
                              height="12"
                              viewBox="0 0 10 12"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M3.31331 4.43333L2.37331 5.38L4.33331 7.33333L7.79331 3.88L6.85331 2.93333L4.33331 5.45333L3.31331 4.43333ZM8.33331 0H1.66665C0.933313 0 0.333313 0.6 0.333313 1.33333V12L4.99998 10L9.66665 12V1.33333C9.66665 0.6 9.06665 0 8.33331 0ZM8.33331 10L4.99998 8.54667L1.66665 10V1.33333H8.33331V10Z"
                                fill="#77809A"
                              />
                            </svg>
                          </th>
                          <th>id</th>
                          <th>сумма</th>
                          <th>дата платежа</th>
                          <th>план/факт</th>
                          <th>нал/безнал</th>
                          <th>отв-ный</th>
                          <th>орг–ция</th>
                          <!--  -->
                          <th>
                            <!-- доход / расход <br />
                            фонд <br /> -->
                            статья
                          </th>
                          <!--  -->
                          <!-- <th>доход / расход</th>
                          <th>фонд</th>
                          <th>статья</th> -->
                          <th>контрагент</th>
                          <th>год по бюджету</th>
                          <th>проект</th>
                          <th>договор</th>
                          <!-- <th>автор записи</th> -->
                          <th>примечание</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="i in projectWInfo" v-bind:key="i.id">
                          <template
                            v-if="
                              i.data.project && i.data.project.id == projectId
                            "
                          >
                            <td></td>
                            <td>
                              <router-link :to="`/wiring/${i.id}`">
                                {{ i.id }}
                              </router-link>
                            </td>
                            <td>
                              {{
                                (+i.data.sum).toLocaleString('ru') + '&nbsp;₽'
                              }}
                            </td>
                            <td>
                              {{
                                new Date(i.data.paymentDate).toLocaleDateString(
                                  'ru-RU'
                                )
                              }}
                            </td>
                            <td>
                              {{ i.data.wiringType == 1 ? 'План' : 'Факт' }}
                            </td>
                            <td>
                              {{ i.data.paymentMethod == 1 ? 'Нал' : 'Безнал' }}
                            </td>
                            <td>
                              <router-link
                                :to="`/employee/${i.data.expert.id}/info`"
                                >{{
                                  i.data.expert
                                    ? i.data.expert['surname'] &&
                                      i.data.expert['middleName'][0] &&
                                      i.data.expert['name'][0]
                                      ? i.data.expert['surname'] +
                                        ' ' +
                                        i.data.expert['name'][0] +
                                        '. ' +
                                        i.data.expert['middleName'][0] +
                                        '.'
                                      : i.data.expert['fullName']
                                    : ''
                                }}</router-link
                              >
                            </td>
                            <td>
                              <router-link
                                :to="`/counterparty/${i.data.organization.id}`"
                                >{{
                                  i.data.organization
                                    ? i.data.organization['shortName']
                                    : ''
                                }}</router-link
                              >
                            </td>
                            <!--  -->
                            <td>
                              <!-- {{
                                i.data.wiringTypeDir
                                  ? wiringTypeDirDict[i.data.wiringTypeDir]
                                  : ''
                              }}
                              <hr class="separator_line" />

                              {{ i.data.funds ? i.data.funds.fund : '' }}
                              <hr class="separator_line" /> -->
                              {{
                                i.data.article ? i.data.article['article'] : ''
                              }}
                            </td>
                            <!--  -->
                            <!-- <td>
                              {{
                                i.data.wiringTypeDir
                                  ? wiringTypeDirDict[i.data.wiringTypeDir]
                                  : ''
                              }}
                              
                            </td>
                            <td>{{ i.data.funds ? i.data.funds.fund : '' }}</td>
                            <td>
                              {{
                                i.data.article ? i.data.article['article'] : ''
                              }}
                            </td> -->
                            <td style="max-width: 120px">
                              <router-link
                                :to="`/counterparty/${i.data.contractor.id}`"
                                >{{
                                  i.data.contractor
                                    ? i.data.contractor['shortName']
                                      ? i.data.contractor['shortName']
                                      : i.data.contractor['fullName']
                                    : ''
                                }}</router-link
                              >
                              <div
                                class="shortName_notificator"
                                v-show="!i.data.contractor['shortName']"
                              >
                                Заполните короткое название контрагента
                              </div>
                            </td>
                            <td>{{ i.data.budgetYear }}</td>
                            <td>
                              <router-link
                                :to="`/project/${i.data.project.id}`"
                                >{{
                                  i.data.project.name
                                    ? i.data.project.name
                                    : i.data.project
                                }}</router-link
                              >
                            </td>
                            <td>
                              <router-link
                                :to="
                                  i.data.contractId
                                    ? `/contract/${i.data.contractId.id}/info`
                                    : '#'
                                "
                                >{{
                                  i.data.contractId
                                    ? i.data.contractId['contractNumber']
                                    : 'Без договора'
                                }}</router-link
                              >
                            </td>
                            <!-- <td><a href="#">Краснов С.М.</a></td> -->
                            <td>{{ i.data.comment ? i.data.comment : '' }}</td>
                            <div class="action">
                              <div>
                                <router-link :to="`/wiring/${i.id}`"
                                  >Просмотр</router-link
                                >
                                <router-link
                                  :to="`/wiringEdit/${i.id}?year=${i.data.budgetYear}`"
                                  >Редактировать</router-link
                                >
                                <a href="#">Удалить</a>
                              </div>
                            </div>
                          </template>
                        </tr>
                      </tbody>
                    </table>
                  </section>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <!--  -->
            <div class="finance">
              <!-- <h2 @click="financeGraphShow = !financeGraphShow">
                <span>Финансы проекта</span>
              </h2>
              <div v-show="financeGraphShow" class="finance-graph">
                <v-btn-toggle>
                  <v-btn active> Факт </v-btn>
                  <v-btn> План </v-btn>
                </v-btn-toggle>
                <v-select dense outlined></v-select>

                <img
                  src="~@/assets/graph.png"
                  alt=""
                  style="width: 100%; display: block; margin: 30px 0 40px 0"
                />

                <div class="legend">
                  <div
                    style="
                      background: #6fcf97;
                      color: #fff;
                      border: 1px solid #6fcf97;
                    "
                  >
                    Доходы
                  </div>
                  <div
                    style="
                      background: #eb5757;
                      color: #fff;
                      border: 1px solid #eb5757;
                    "
                  >
                    Расходы
                  </div>
                  <div
                    style="
                      background: #fff;
                      color: #000;
                      border: 1px solid #f2c94c;
                    "
                  >
                    Перераспределение
                  </div>
                  <div
                    style="
                      background: #3577be;
                      color: #fff;
                      border: 1px solid #3577be;
                    "
                  >
                    Прибыль
                  </div>
                </div>
              </div> -->

              <!-- <h2>
                <span @click="passportShow = !passportShow">Паспорт</span>
              </h2> -->
              <!-- <div v-show="passportShow">
                <v-btn outlined rounded class="download"></v-btn>
                <v-btn>Все проводки</v-btn>
              </div>
              <table v-show="passportShow" class="passport">
                <thead>
                  <tr>
                    <th colspan="2">статья</th>
                    <th>план</th>
                    <th>факт</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="bold">
                    <td>
                      <v-checkbox></v-checkbox>
                    </td>
                    <td>Сумма контракта</td>
                    <td>
                      {{ projectFinance.contractSum.plan.toLocaleString('ru') }}
                      ₽
                    </td>
                    <td>
                      {{ projectFinance.contractSum.fact.toLocaleString('ru') }}
                      ₽
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>
                      <v-checkbox></v-checkbox>
                    </td>
                    <td>
                      1.1. НДС согласно контракту за вычетом НДС уплачиваемого
                      соисполнителем
                    </td>
                    <td>{{ projectFinance.nds.plan.toLocaleString('ru') }}</td>
                    <td>{{ projectFinance.nds.fact.toLocaleString('ru') }}</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>
                      <v-checkbox></v-checkbox>
                    </td>
                    <td>1.2. Безналичная оплата соисполнителей</td>
                    <td>
                      {{
                        projectFinance.cashlessPayment.plan.toLocaleString('ru')
                      }}
                      ₽
                    </td>
                    <td>
                      {{
                        projectFinance.cashlessPayment.fact.toLocaleString('ru')
                      }}
                      ₽
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>
                      <v-checkbox></v-checkbox>
                    </td>
                    <td>
                      1.3. Безвозвратное обеспечение (премии страховым
                      компаниям, комиссии банков за банковсике гарантии и т.п.)
                    </td>
                    <td>
                      {{
                        projectFinance.irretrievablуGuarantee.plan.toLocaleString(
                          'ru'
                        )
                      }}
                    </td>
                    <td>
                      {{
                        projectFinance.irretrievablуGuarantee.fact.toLocaleString(
                          'ru'
                        )
                      }}
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>
                      <v-checkbox></v-checkbox>
                    </td>
                    <td>1.4. Безналичный резерв</td>
                    <td>
                      {{
                        projectFinance.cashlessReserve.plan.toLocaleString('ru')
                      }}
                    </td>
                    <td>
                      {{
                        projectFinance.cashlessReserve.fact.toLocaleString('ru')
                      }}
                    </td>
                    <td></td>
                  </tr>
                  <tr class="bold">
                    <td></td>
                    <td>
                      Vi Сумма Контракта без НДС, за вычетом безвозвратного
                      обеспечения и сумм, выплаченных
                      организациям-соисполнителям
                    </td>
                    <td>
                      {{
                        projectFinance.viContractSum.plan.toLocaleString('ru')
                      }}
                      ₽
                    </td>
                    <td>
                      {{
                        projectFinance.viContractSum.fact.toLocaleString('ru')
                      }}
                      ₽
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>Ставка налога</td>
                    <td>{{ projectFinance.taxRate.toLocaleString('ru') }} %</td>
                    <td></td>
                    <td><a href="#">изменить ставку</a></td>
                  </tr>
                  <tr>
                    <td>
                      <v-checkbox></v-checkbox>
                    </td>
                    <td>2.1. Сумма налога</td>
                    <td>
                      {{ projectFinance.taxSum.plan.toLocaleString('ru') }} ₽
                    </td>
                    <td>
                      {{ projectFinance.taxSum.fact.toLocaleString('ru') }} ₽
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>Ставка комиссии банка за снятие наличности</td>
                    <td>
                      {{
                        projectFinance.cashingBankComission.toLocaleString('ru')
                      }}
                      %
                    </td>
                    <td></td>
                    <td><a href="#">изменить ставку</a></td>
                  </tr>
                  <tr>
                    <td>
                      <v-checkbox></v-checkbox>
                    </td>
                    <td>2.2. Сумма комиссии</td>
                    <td>
                      {{
                        projectFinance.cashingComissionSum.plan.toLocaleString(
                          'ru'
                        )
                      }}
                      ₽
                    </td>
                    <td>
                      {{
                        projectFinance.cashingComissionSum.fact.toLocaleString(
                          'ru'
                        )
                      }}
                      ₽
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>Ставка средних накладных налогов</td>
                    <td>
                      {{ projectFinance.overheadRate.toLocaleString('ru') }} %
                    </td>
                    <td></td>
                    <td><a href="#">изменить ставку</a></td>
                  </tr>
                  <tr>
                    <td>
                      <v-checkbox></v-checkbox>
                    </td>
                    <td>2.3. Сумма накладных расходов на проект</td>
                    <td>
                      {{
                        projectFinance.overheadAmountSum.plan.toLocaleString(
                          'ru'
                        )
                      }}
                      ₽
                    </td>
                    <td>
                      {{
                        projectFinance.overheadAmountSum.fact.toLocaleString(
                          'ru'
                        )
                      }}
                      ₽
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>Ставка расходов на ЗП сотрудников</td>
                    <td>
                      {{ projectFinance.payrollCostRate.toLocaleString('ru') }}
                      %
                    </td>
                    <td></td>
                    <td><a href="#">изменить ставку</a></td>
                  </tr>
                  <tr>
                    <td>
                      <v-checkbox></v-checkbox>
                    </td>
                    <td>2.4. ЗП сотрудников на проект</td>
                    <td>
                      {{
                        projectFinance.payrollAmountSum.plan.toLocaleString(
                          'ru'
                        )
                      }}
                      ₽
                    </td>
                    <td>
                      {{
                        projectFinance.payrollAmountSum.fact.toLocaleString(
                          'ru'
                        )
                      }}
                      ₽
                    </td>
                    <td></td>
                  </tr>
                  <tr class="bold">
                    <td></td>
                    <td>
                      Hi Сумма Контракта без НДС, за вычетом безвозвратного
                      обеспечения и сумм, выплаченных
                      организациям-соисполнителям, за вычетом постоянных
                      издержек
                    </td>
                    <td>
                      {{
                        projectFinance.hiContractSum.plan.toLocaleString('ru')
                      }}
                      ₽
                    </td>
                    <td>
                      {{
                        projectFinance.hiContractSum.fact.toLocaleString('ru')
                      }}
                      ₽
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>
                      <v-checkbox></v-checkbox>
                    </td>
                    <td>3.1. Схема бонусов</td>
                    <td>
                      {{ projectFinance.bonusScheme.plan.toLocaleString('ru') }}
                      ₽
                    </td>
                    <td>
                      {{ projectFinance.bonusScheme.fact.toLocaleString('ru') }}
                      ₽
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>
                      <v-checkbox></v-checkbox>
                    </td>
                    <td>3.2. Оплата внешних экспертов</td>
                    <td>
                      {{
                        projectFinance.externalExpertsPayout.plan.toLocaleString(
                          'ru'
                        )
                      }}
                      ₽
                    </td>
                    <td>
                      {{
                        projectFinance.externalExpertsPayout.fact.toLocaleString(
                          'ru'
                        )
                      }}
                      ₽
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>
                      <v-checkbox></v-checkbox>
                    </td>
                    <td>3.3. Другие проектные расходы</td>
                    <td>
                      {{
                        projectFinance.otherProjectExpences.plan.toLocaleString(
                          'ru'
                        )
                      }}
                      ₽
                    </td>
                    <td>
                      {{
                        projectFinance.otherProjectExpences.fact.toLocaleString(
                          'ru'
                        )
                      }}
                      ₽
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>
                      <v-checkbox></v-checkbox>
                    </td>
                    <td>3.4. Резерв</td>
                    <td>
                      {{ projectFinance.reserve.plan.toLocaleString('ru') }} ₽
                    </td>
                    <td>
                      {{ projectFinance.reserve.fact.toLocaleString('ru') }} ₽
                    </td>
                    <td></td>
                  </tr>
                  <tr class="bold">
                    <td></td>
                    <td>
                      Pri - Прибыль проекта (Сумма контрактов без НДС,
                      безвозвр.обеспеч.,сумм организаций-соисполнителей, за
                      вычетом постоянных издержек, за вычетом переменных
                      издержек)
                    </td>
                    <td>
                      {{
                        projectFinance.priProjectProfit.plan.toLocaleString(
                          'ru'
                        )
                      }}
                      ₽
                    </td>
                    <td>
                      {{
                        projectFinance.priProjectProfit.fact.toLocaleString(
                          'ru'
                        )
                      }}
                      ₽
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>
                      Profit Доля проекта в прибыли (Отношение прибыли проекта к
                      прибыли команды)
                    </td>
                    <td>
                      {{ projectFinance.profitRate.plan.toLocaleString('ru') }}
                      %
                    </td>
                    <td>
                      {{ projectFinance.profitRate.fact.toLocaleString('ru') }}
                      %
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </table> -->

              <!-- <section>
                <h2>
                  <span> Проводки </span>
                  <v-btn outlined rounded class="download"></v-btn>
                  <a :href="`/newwiring/${projectId}`">
                    <v-btn>Создать проводку</v-btn>
                  </a>
                </h2>
                <table>
                  <thead>
                    <tr>
                      <th>
                        <svg
                          width="10"
                          height="12"
                          viewBox="0 0 10 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3.31331 4.43333L2.37331 5.38L4.33331 7.33333L7.79331 3.88L6.85331 2.93333L4.33331 5.45333L3.31331 4.43333ZM8.33331 0H1.66665C0.933313 0 0.333313 0.6 0.333313 1.33333V12L4.99998 10L9.66665 12V1.33333C9.66665 0.6 9.06665 0 8.33331 0ZM8.33331 10L4.99998 8.54667L1.66665 10V1.33333H8.33331V10Z"
                            fill="#77809A"
                          />
                        </svg>
                      </th>
                      <th>id проводки</th>
                      <th>сумма</th>
                      <th>дата платежа</th>
                      <th>план/факт</th>
                      <th>нал/безнал</th>
                      <th>отв-ный</th>
                      <th>орг–ция</th>
                      <th>доход / расход</th>
                      <th>фонд</th>
                      <th>статья</th>
                      <th>контрагент</th>
                      <th>год по бюджету</th>
                      <th>проект</th>
                      <th>договор</th>
                      <th>автор записи</th>
                      <th>примечание</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="i in projectWInfo" v-bind:key="i.id">
                      <template
                        v-if="i.data.project && i.data.project.id == projectId"
                      >
                        <td></td>
                        <td>
                          <router-link :to="`/wiringEdit/${i.id}`">
                            {{ i.id }}
                          </router-link>
                        </td>
                        <td>{{ i.data.sum }}</td>
                        <td>{{ i.data.paymentDate }}</td>
                        <td>{{ i.data.wiringType == 1 ? 'План' : 'Факт' }}</td>
                        <td>
                          {{ i.data.paymentMethod == 1 ? 'Нал' : 'Безнал' }}
                        </td>
                        <td>
                          <router-link
                            :to="`/employee/${i.data.expert.id}/info`"
                            >{{
                              i.data.expert ? i.data.expert['fullName'] : ''
                            }}</router-link
                          >
                        </td>
                        <td>
                          <router-link
                            :to="`/counterparty/${i.data.organization.id}`"
                            >{{
                              i.data.organization
                                ? i.data.organization['shortName']
                                : ''
                            }}</router-link
                          >
                        </td>
                        <td>
                          {{
                            i.data.wiringTypeDir
                              ? wiringTypeDirDict[i.data.wiringTypeDir]
                              : ''
                          }}
                        </td>
                        <td>{{ i.data.funds ? i.data.funds.fund : '' }}</td>
                        <td>
                          {{ i.data.article ? i.data.article['article'] : '' }}
                        </td>
                        <td>
                          <router-link
                            :to="`/counterparty/${i.data.contractor.id}`"
                            >{{
                              i.data.contractor
                                ? i.data.contractor['fullName']
                                : ''
                            }}</router-link
                          >
                        </td>
                        <td>{{ i.data.budgetYear }}</td>
                        <td>
                          <router-link :to="`/project/${i.data.project.id}`">{{
                            i.data.project.name
                              ? i.data.project.name
                              : i.data.project
                          }}</router-link>
                        </td>
                        <td>
                          <router-link
                            :to="
                              i.data.contractId
                                ? `/contract/${i.data.contractId.id}/info`
                                : '#'
                            "
                            >{{
                              i.data.contractId
                                ? i.data.contractId['contractNumber']
                                : 'Без договора'
                            }}</router-link
                          >
                        </td>
                        <td><a href="#">Краснов С.М.</a></td> 
                        <td>{{ i.data.comment ? i.data.comment : '' }}</td>
                        <div class="action">
                          <div>
                            <router-link :to="`/wiringEdit/${i.id}`"
                              >Просмотр</router-link
                            >
                            <router-link
                              :to="`/wiringEdit/${i.id}?year=${i.data.budgetYear}`"
                              >Редактировать</router-link
                            >
                            <a href="#">Удалить</a>
                          </div>
                        </div>
                      </template>
                    </tr>
                  </tbody>
                </table>
              </section> -->
            </div>
          </v-tab-item>

          <v-tab-item>
            <participations-component
              :experts="experts"
              :partners="partners"
              :project-id="encrypted.id"
              :addit="encrypted.addit"
              :participation-encrypted="encrypted.data2"
            ></participations-component>
          </v-tab-item>
        </v-tabs>
      </div>
    </div>
  </v-container>
</template>

<script>
import { projectKeyHolder } from '@/modules/Projects';
import {
  dictToObject,
  getExpertShortName,
  loadDataToObject,
} from '@/modules/CommonUtils';
import { projectStatusDict, supMarketDict } from '@/modules/NSI';
import ParticipationsComponent from '@/components/ParticipationsComponent';
import {
  projectFinance,
  wiringTypeDirDict,
  finPassport,
  calculatePassport,
} from '@/components/register/WireDict';
// import LettersComponent from "@/components/LettersComponent";
import { wDec, wGet } from '@/modules/Wirings';

export default {
  name: 'ProjectComponent',
  components: { ParticipationsComponent },
  props: {},
  data() {
    return {
      panel: [2],
      disabled: false,
      financeGraphShow: false,
      passportShow: false,
      encrypted: {},
      projectCommonInfo: {},
      contracts: [],
      projectWInfo: [],
      projectWenc: [],
      projectStatusDict: dictToObject(projectStatusDict),
      supMarketDict: dictToObject(supMarketDict),
      projectId: null,
      projectFinance: {},
      wiringTypeDirDict: wiringTypeDirDict,
      apiLoaded: false,
    };
  },
  methods: {
    loadData: loadDataToObject,
    loadW: wGet,
    finPassport: finPassport,
    calculatePassport: calculatePassport,
  },
  computed: {
    offerExpertName() {
      return getExpertShortName(
        this.offerExperts.find(
          (e) => e.id === this.projectCommonInfo.offerExpert
        )
      );
    },
  },
  async beforeMount() {
    if (!projectKeyHolder.checkOrRequestKey()) return;
    this.projectId = this.$route.params.id;
    await Promise.all([
      this.loadData(
        '/supmain/experts?page=0&size=100&sort=id,asc&search=isRolePartner:true',
        'partners',
        true
      ),
      this.loadData(
        '/supmain/experts?page=0&size=100&sort=id,asc&search=isNonPartnerParticipant:true',
        'experts',
        true
      ),
      this.loadData(
        '/supmain/experts?page=0&size=100&sort=id,asc&search=isRoleOffer:true',
        'offerExperts',
        true
      ),
      this.loadData('/supmain/projects/' + this.projectId, 'encrypted', false),
    ]);
    const getFullName = function (items) {
      return items.map((e) => {
        e.fullName = [e.surname, e.name, e.middleName].join(' ');
        if (e.fullName === '  ') e.fullName = 'Без имени';
        return e;
      });
    };
    this.offerExperts = getFullName(this.offerExperts);
    this.partners = getFullName(this.partners);
    this.experts = getFullName(this.experts);

    this.projectCommonInfo = await projectKeyHolder.decrypt(
      this.encrypted.data,
      this.encrypted.addit
    );
    this.projectCommonInfo.number =
      this.encrypted.year + '-' + this.encrypted.numberInYear;
    console.log('projectCommonInfo', this.projectCommonInfo);
    this.projectWenc = await wGet(this.projectCommonInfo.year);
    this.projectWInfo = await wDec(this.projectWenc);
    this.projectWInfo.sort((a, b) => b.id - a.id);
    console.log('projectWInfo', this.projectWInfo);
    // MOCK DATA:
    this.projectFinance = projectFinance();
    this.projectWInfo.forEach((item) => {
      if (item.data.project && item.data.project.id == this.projectId) {
        console.log(item.data.project.id, this.projectId);
        if (!this.contracts.includes(item.data.contractId['contractNumber'])) {
          this.contracts.push(item.data.contractId['contractNumber']);
        }
        this.projectFinance = finPassport(item, this.projectFinance);
      }
    });
    this.projectFinance = calculatePassport(this.projectFinance);
    this.apiLoaded = true;

    console.log('project finance', this.projectFinance);
  },
};
</script>
<style scoped>
.table_font {
  font-size: 11px;
  font-weight: 300;
}
.table_font td {
  font-size: small;
}
.shortName_notificator {
  font-size: smaller;
  color: #919fbc;
}
.separator_line {
  color: #919fbc;
}
</style>
