<template>
  <div class="grid50" style="flex-wrap: wrap;">

    <div style="width: 100%; margin-bottom: 20px; padding-bottom: 0; margin-right: 0;">
      <span v-if="formLoaded && !validate()" style="color:red"> Вклады заполнены некорректно </span>
      <h2>Привод</h2>
      <v-form ref="form">
        <v-simple-table class="participations-table">
          <template v-slot:default>
            <thead>
            <tr>
              <th></th>
              <th v-for="(t, i) in subWeightsPDict.map(e=>e.title)" :key="'titleweight_'+i">{{
                  t
                }} (%)
              </th>
              <th>
                Итого вклад
              </th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>Вес по умолчанию</td>
              <td v-for="(w, i) in subWeightsPDict.map(e=>e.default)" :key="'defweight_'+i">{{ w }}</td>
              <td>
                {{ subWeightsPDict.reduce((acc, e) => acc + e.default, 0) }}
              </td>
            </tr>
            <tr>
              <td>Вес в проекте</td>
              <td v-for="(c, i) in subWeightsPDict.map(e=>e.code)" :key="'projectweights_'+i">
                <v-text-field
                    outlined dense
                    :value="projectParticipationInfo.weights.P[c]"
                    @input="projectParticipationInfo.weights.P[c]=validInput($event);formModified=true"
                    :rules="[validatePercent]"
                ></v-text-field>
              </td>
              <td :style="weightsSum() === 100 ? '' : 'color:red'">
                {{ weightsSum() }}
              </td>
            </tr>
            <tr>
              <th colspan="10" style="padding-top: 40px !important;">
                партнеры
              </th>
            </tr>
            <template v-for="(part, partIndex) in projectParticipationInfo.participations.P">
              <tr v-if="expertById(part.expert).isRolePartner" :key="partIndex + '_P_part'">
                <td>
                  <v-icon color="#3577BE" style="cursor:pointer;" @click="removeExpert('P', part.expert)">mdi-close
                  </v-icon>
                  {{ expertById(part.expert).fullName }}
                </td>
                <td v-for="(c, i) in subWeightsPDict.map(e=>e.code)" :key="'participantWeights_P_'+part.expert+'_'+i">
                  <v-text-field
                      outlined dense
                      @input="participationPInput(part);formModified=true"
                      v-model="projectParticipationInfo.participations.P.find(e=>e.expert===part.expert).values[c]"
                      :rules="[validatePercent]"></v-text-field>
                </td>
                <td>
                  <v-text-field
                      outlined dense
                      @input="participationPInputOverall(part);formModified=true"
                      :rules="[validatePercent]"
                      v-model="projectParticipationInfo.participations.P.find(e=>e.expert===part.expert).values.OVERALL"></v-text-field>
                </td>
              </tr>
            </template>
            <tr>
              <td style="border: none;" colspan="10"><a href="#" @click.prevent="addExpert('P', 'PARTNER')">+
                добавить партнера</a></td>
            </tr>
            <tr>
              <th colspan="10" style="padding-top: 40px !important;">
                сотрудники
              </th>
            </tr>
            <template v-for="(part, partIndex) in projectParticipationInfo.participations.P">
              <tr v-if="!expertById(part.expert).isRolePartner" :key="partIndex + '_P_expt'">
                <td>
                  <v-icon color="#3577BE" style="cursor:pointer;" @click="removeExpert('P', part.expert)">mdi-close
                  </v-icon>
                  {{ expertById(part.expert).fullName }}
                </td>
                <td v-for="(c, i) in subWeightsPDict.map(e=>e.code)" :key="'participantWeights_P_'+part.expert+'_'+i">
                  <v-text-field
                      outlined dense
                      @input="participationPInput(part);formModified=true"
                      v-model="projectParticipationInfo.participations.P.find(e=>e.expert===part.expert).values[c]"
                      :rules="[validatePercent]"></v-text-field>
                <td>
                  <v-text-field
                      outlined dense
                      :rules="[validatePercent]"
                      @input="participationPInputOverall(part);formModified=true"
                      v-model="projectParticipationInfo.participations.P.find(e=>e.expert===part.expert).values.OVERALL"></v-text-field>
                </td>
              </tr>
            </template>
            <tr>
              <td style="border: none;" colspan="10">
                <a href="#" @click.prevent="addExpert('P', 'PARTICIPANT')">+ добавить сотрудника</a>
              </td>
            </tr>
            <tr class="last-row">
              <td>итого</td>
              <td
                  v-for="(c, i) in subWeightsPDict.map(e=>e.code)"
                  :style="((v = participationSum_P(c)) < 0 || v > projectParticipationInfo.weights.P[c] || isNaN(v)) ? 'color:red' : ''"
                  :key="'participationSum_P_'+i">
                {{ v }}
              </td>
              <td :style="((v = participationSum_P('OVERALL')) !== 100 ) ? 'color:red' : ''">{{ v }}</td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-form>

    </div>

    <div style="margin-right: 20px; padding-bottom: 0;  height: fit-content;">
      <h2>Руководство</h2>

      <template>
        <v-simple-table>
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-left">
                партнеры
              </th>
              <th class="text-left">
                вклад
              </th>
            </tr>
            </thead>
            <tbody>
            <template v-for="(part, partIndex) in projectParticipationInfo.participations.R">
              <tr v-if="expertById(part.expert).isRolePartner" :key="partIndex + '_R_part'">
                <td>
                  <v-icon color="#3577BE" style="cursor:pointer;" @click="removeExpert('R', part.expert)">mdi-close</v-icon>
                  {{ expertById(part.expert).fullName }}
                </td>
                <td>
                  <v-text-field
                      outlined dense
                      :rules="[validatePercent]"
                      @input="projectParticipationInfo.participations.R.find(e=>e.expert===part.expert).values.OVERALL=validInput($event);formModified=true"
                      v-model="projectParticipationInfo.participations.R.find(e=>e.expert===part.expert).values.OVERALL"
                  ></v-text-field>
                </td>
              </tr>
            </template>
            <tr>
              <td style="border: none;" colspan="10"><a href="#" @click.prevent="addExpert('R', 'PARTNER')">+
                добавить партнера</a></td>
            </tr>
            <tr>
              <th class="text-left">
                сотрудники
              </th>
              <th class="text-left">
                вклад
              </th>
            </tr>
            <template v-for="(part, partIndex) in projectParticipationInfo.participations.R">
              <tr v-if="!expertById(part.expert).isRolePartner" :key="partIndex + '_R_expt'">
                <td>
                  <v-icon color="#3577BE" style="cursor:pointer;" @click="removeExpert('R', part.expert)">mdi-close</v-icon>
                  {{ expertById(part.expert).fullName }}
                </td>
                <td>
                  <v-text-field
                      outlined dense
                      :rules="[validatePercent]"
                      @input="projectParticipationInfo.participations.R.find(e=>e.expert===part.expert).values.OVERALL=validInput($event);formModified=true"
                      v-model="projectParticipationInfo.participations.R.find(e=>e.expert===part.expert).values.OVERALL"
                  ></v-text-field>
                </td>
              </tr>
            </template>
            <tr>
              <td style="border: none;" colspan="10"><a href="#" @click.prevent="addExpert('R', 'PARTICIPANT')">+
                добавить сотрудника</a></td>
            </tr>
            <tr class="last-row">
              <td>итого</td>
              <td :style="(v = participationSum_R()) !== 100 ? 'color:red' : ''">
                {{ v }}</td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </template>


    </div>

    <div style="padding-bottom: 0; height: fit-content;">
      <h2>Исполнение</h2>

      <template>
        <v-simple-table>
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-left">
                партнеры
              </th>
              <th class="text-left">
                вклад
              </th>
            </tr>
            </thead>
            <tbody>
            <template v-for="(part, partIndex) in projectParticipationInfo.participations.I">
              <tr v-if="expertById(part.expert).isRolePartner" :key="partIndex + '_I_part'">
                <td>
                  <v-icon color="#3577BE" style="cursor:pointer;" @click="removeExpert('I', part.expert)">mdi-close</v-icon>
                  {{ expertById(part.expert).fullName }}
                </td>
                <td>
                  <v-text-field
                      outlined dense
                      :rules="[validatePercent]"
                      @input="projectParticipationInfo.participations.I.find(e=>e.expert===part.expert).values.OVERALL=validInput($event);formModified=true"
                      v-model="projectParticipationInfo.participations.I.find(e=>e.expert===part.expert).values.OVERALL"
                  ></v-text-field>
                </td>
              </tr>
            </template>
            <tr>
              <td style="border: none;" colspan="10"><a href="#" @click.prevent="addExpert('I', 'PARTNER')">+
                добавить партнера</a></td>
            </tr>
            <tr>
              <th class="text-left">
                сотрудники
              </th>
              <th class="text-left">
                вклад
              </th>
            </tr>
            <template v-for="(part, partIndex) in projectParticipationInfo.participations.I">
              <tr v-if="!expertById(part.expert).isRolePartner" :key="partIndex + '_I_expt'">
                <td>
                  <v-icon color="#3577BE" style="cursor:pointer;" @click="removeExpert('I', part.expert)">mdi-close</v-icon>
                  {{ expertById(part.expert).fullName }}
                </td>
                <td>
                  <v-text-field
                      outlined dense
                      :rules="[validatePercent]"
                      @input="projectParticipationInfo.participations.I.find(e=>e.expert===part.expert).values.OVERALL=validInput($event);formModified=true"
                      v-model="projectParticipationInfo.participations.I.find(e=>e.expert===part.expert).values.OVERALL"
                  ></v-text-field>
                </td>
              </tr>
            </template>
            <tr>
              <td style="border: none;" colspan="10"><a href="#" @click.prevent="addExpert('I', 'PARTICIPANT')">+
                добавить сотрудника</a></td>
            </tr>
            <tr class="last-row">
              <td>итого</td>
              <td :style="(v = participationSum_I()) !== 100 ? 'color:red' : ''">
                {{ v }}</td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </template>

    </div>

    <v-dialog
        persistent
        v-model="showExpertsToChooseDialog"
        width="500"
    >
      <add-participant-dialog
          :experts="expertsToChoose"
          :experts-to-exclude="expertsToExclude"
          v-if="showExpertsToChooseDialog"
          @close="showExpertsToChooseDialog=false"
          @select="showExpertsToChooseDialog=false;onExpertChosen($event)"
      ></add-participant-dialog>
    </v-dialog>
    <div class="bottom-buttons" style="border: none">
      <v-btn disabled v-if="!formModified">Сохранить изменения</v-btn>
      <v-btn @click="saveParticipations()" v-else>Сохранить изменения</v-btn>
    </div>
  </div>
</template>

<script>
import AddParticipantDialog from "@/components/dialogs/AddParticipantDialog";
import {normalizeNumber} from "@/modules/CommonUtils";
import {subWeightsPDict} from "@/modules/NSI";
import api from "@/modules/api";
import {projectKeyHolder} from "@/modules/Projects";

export default {
  name: "ParticipationsComponent",
  components: {AddParticipantDialog},
  props: ['partners', 'experts', 'projectId', 'addit', 'participationEncrypted'],
  data() {
    return {
      subWeightsPDict: subWeightsPDict,
      expertsToChoose: [], //для выбора в диалоге добавить
      showExpertsToChooseDialog: false,
      positionToChoose: null, //'P', 'R', 'I'
      expertsToExclude: [], //кого исключить из диалога добавить. Кто уже выбран (список идшников)
      projectParticipationInfo: {
        weights: {P: {}},
        participations: {P: [], R: [], I: []}
      },
      formLoaded: false,
      formModified: false
    }
  },
  methods: {
    normalizeNumber: normalizeNumber,
    validInput(v) {
      let res = this.normalizeNumber(v, 0, 100, 2)
      if (res == null) return v
      return res
    },
    validatePercent: v => (v == null || v.length === 0 || v === '0' || v === 0 || !!normalizeNumber(v, 0, 100, 2) || ''),

    weightsSum() {
      return Object.values(this.projectParticipationInfo.weights.P).reduce((acc, e) => acc + (+e), 0)
    },

    addExpert(positionToChoose, roleToChoose) {
      this.positionToChoose = positionToChoose
      if (roleToChoose === 'PARTNER') this.expertsToChoose = this.partners
      else if (roleToChoose === 'PARTICIPANT') this.expertsToChoose = this.experts
      this.expertsToExclude = this.projectParticipationInfo.participations[this.positionToChoose].map(e => e.expert)
      this.showExpertsToChooseDialog = true
    },

    removeExpert(postion, expertId) {
      const participations = this.projectParticipationInfo.participations[postion]
      for (let i = 0; i < participations.length; i++) {
        if (participations[i].expert === expertId) participations.splice(i, 1)
      }
    },

    onExpertChosen(expert) {
      this.projectParticipationInfo.participations[this.positionToChoose].push({expert: expert.id, values: {}})
      this.showExpertsToChooseDialog = false
      this.positionToChoose = null
      this.expertsToExclude = []
    },

    expertById(id) {
      let e = this.experts.find(e => e.id === id)
      if (!e) e = this.partners.find(e => e.id === id)
      return e
    },

    participationPInput(part) {
      part.values.OVERALL = 0
      let acc = 0
      for (let key of Object.keys(part.values)) {
        if (part.values[key]) {
          let v = this.normalizeNumber(part.values[key], 0, 100, 2)
          if (v == null) {
            acc = NaN
            break
          }
          part.values[key] = v
          acc += v
        }
      }
      part.values.OVERALL = acc
      this.$forceUpdate()
    },

    participationPInputOverall(part) {
      //если просто удалить поля и сделать forceUpdate, данные из текстбоксов не сотрутся. поэтому такая конструкция
      for (let key of Object.keys(part.values)) {
        if (key !== 'OVERALL') part.values[key] = ''
      }
      this.$forceUpdate()
      for (let key of Object.keys(part.values)) {
        if (key !== 'OVERALL') delete part.values[key]
      }
      this.$forceUpdate()
    },

    participationSum_P(pos) {
      return this.projectParticipationInfo.participations.P.reduce((acc, e) => {
        return e.values[pos] ? acc + (+e.values[pos]) : acc
      }, 0)
    },

    participationSum_R() {
      return this.projectParticipationInfo.participations.R.reduce((acc, e) => {
        return e.values.OVERALL ? acc + (+e.values.OVERALL) : acc
      }, 0)
    },

    participationSum_I() {
      return this.projectParticipationInfo.participations.I.reduce((acc, e) => {
        return e.values.OVERALL ? acc + (+e.values.OVERALL) : acc
      }, 0)
    },

    async saveParticipations() {
      const projectWithParticipation = {
        id: this.projectId,
        data2: (await projectKeyHolder.encrypt(this.projectParticipationInfo, this.addit)).data
      }
      console.log(projectWithParticipation)
      const req = await api.putJson('/supmain/projects/participations/',projectWithParticipation)
      console.log(req)
      if (req.ok) {
        this.formModified = false
      } else {
        alert('Что-то пошло не так')
      }
    },

    validate(){
      if(!this.$refs.form.validate()) return false
      if(this.weightsSum() !== 100) return false

      for(let c of subWeightsPDict.map(e=>e.code)){
        let v = this.participationSum_P(c)
        if(v < 0 || v > this.projectParticipationInfo.weights.P[c] || isNaN(v)) return false
      }
      if(this.participationSum_P('OVERALL') !== 100) return false
      if(this.participationSum_R('OVERALL') !== 100) return false
      if(this.participationSum_I('OVERALL') !== 100) return false

      return true
    }
  },
  async beforeMount() {
    if(!this.participationEncrypted) {
      this.projectParticipationInfo.weights.P = Object.fromEntries(subWeightsPDict.map(e => {
        return [e.code, e.default]
      }))
    } else {
      this.projectParticipationInfo = await projectKeyHolder.decrypt(this.participationEncrypted, this.addit)
    }
  },
  mounted() {
    this.formLoaded = true
  }
}
</script>

<style scoped>

</style>